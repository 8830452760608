<h1>Wybierz usługę która chciałbyś rozliczyć:</h1>
<div class="row">
  <div class="col-md-2 offset-md-2 text-center mt-5">
    <a [routerLink]="['/mintos']">
      <img src="../../../../assets/images/mintos-logo.png" class="width"/>
    </a>
  </div>
  <div class="col-md-2 offset-md-3 text-center mt-5">
    <a [routerLink]="['/estateguru']">
      <img src="../../../../assets/images/estateguru-logo.png" class="width"/>
    </a>
  </div>
</div>
