import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUploadFilesWrapperDto } from 'src/app/shared/models/upload.model';

@Injectable()
export class ResultsCommunicationService {
  public currencies$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  public results$: BehaviorSubject<IUploadFilesWrapperDto> = new BehaviorSubject(null);

}
