import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from 'src/app/shared/models/api-response.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(errorReponse => {
                let errMsg: string;
                let toastrMsg = '';
                if (errorReponse instanceof HttpErrorResponse) {
                    const apiResponse = (<ApiResponse>errorReponse.error);
                    if (apiResponse != null && apiResponse.Message instanceof Array) {
                        apiResponse.Message.forEach(x => {
                            this.toastr.error(x);
                        });
                    }
                } else {
                    errMsg = errorReponse.message ? errorReponse.message : errorReponse.toString();
                    toastrMsg = errMsg;
                    this.toastr.error(toastrMsg);
                }
                return throwError(errMsg);
            }));
    }
}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
