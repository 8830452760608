<h1>Jak działa platforma Wirepit</h1>
<p>
  Na stronie Mitos w zakładce „Wyciąg z konta” dla każdej w walut, w którą zainwestowaliśmy należy wygenerować plik Excel. Przy czym do wygenerowania pliku Excel należy zaznaczyć „Datę rozpoczęcia” tj. 01-01-{{year}} i „Datę zakończenia” tj. 31-12-{{year}}, pole „Rodzaj płatności” nie musi zostać zaznaczone. Po kliknięciu w „Wyszukaj” pod spodem pojawi się wyciąg podsumowujący w uprzednio zaznaczonej przez nas walucie. Następnie po kliknięciu w pole „Pobierz wybraną listę” Mintos wygeneruje i zapisze na naszym dysku plik w formacie Excel
</p>
<p>
  Po wejściu na stronę: <a href="/">https://wirepit.pl/</a> i kliknięciu w przycisk „Zaczynamy” wyświetla się na nam ankieta dotycząca tego czy inwestowaliśmy w Mintos w więcej niż jednej walucie.
</p>
<p>
  Po zaznaczeniu odpowiedzi wskazującej, że inwestowaliśmy w więcej niż jednej walucie, wyświetlają się poszczególne waluty, z pośród których należy zaznaczyć te waluty, w które inwestowaliśmy a następnie kliknąć w przycisk „Dalej”. Kolejnym krokiem jest wysłanie z dysku rozliczeń w formie plików Excel pobranych ze strony Minosa osobno dla każdej z walut.
</p>
<p>
  W aplikacji Wirepit wygeneruje się plik Excel z rozliczeniem dochodu, informacją o podatku do zapłaty w danej walucie.
</p>
<p>
  Jeśli więc inwestujemy w kilku walutach na Mintos powinniśmy dla każdej waluty wygenerować na stronie Minosa osobny plik Excel a następnie wysyłać pliki pojedynczo.
</p>
<p>
  Ostateczną wartością podatku do zapłaty będzie suma podatków wskazanych w każdym pliku Excel wygenerowanym ze strony Wirepit.
</p>
<div class="card card-body">
  <p>Oto przykładowy wynikowy plik programu Microsoft Excel wygenerowany przez Wirepit:</p>
  <ul>
    <li>Na zielono zaznaczono transakcję który przyniosły zysk</li>
    <li>Dodano kolumnę z kursami walut odpowiadającymi dacie transakcji</li>
    <li>Po prawej stronie umieszczono wynik obliczeń tj. między innymi należny do zapłaty podatek</li>
  </ul>
  <img src="../../assets/images/wynik-excel.JPG" width="100%" />
</div>

<i>Wymienione powyżej  informacje nie stanowią wykładni prawa ani nie mają charakteru czynności doradztwa podatkowego czy finansowego, a jedynie maja charakter informacyjny.</i>
