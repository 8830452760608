<div class="sidebar" [class.opened]="sidebarOpened">
  <nav>
    <div class="container">
      <img src="../../assets/images/logo.svg" alt="" class="img-fluid">
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/strona-glowna']">Strona główna</a></div>
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/jak-to-dziala']">Jak to działa</a></div>
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/szczegoly']">Szczegóły</a></div>
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/cennik']">Cennik</a></div>
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/faq']">FAQ</a></div>
      <div><a (click)="closeSidebar()" class="link" [routerLink]="['/kontakt']">Kontakt</a></div>
      <!-- <div><a class="link" [routerLink]="['/o-nas']">O nas</a></div> -->
    </div>
  </nav><!-- .main-nav -->
</div>
<div class="overlay" [class.opened]="sidebarOpened" (click)="onHamburgerClick()"></div>
<header id="header" (scroll)="onScroll()" [class.header-scrolled]="scrolled">
  <img class="hamburger" src="./../../assets/images/hamburger.svg" (click)="onHamburgerClick()"/>
  <div class="container">
    <div class="logo float-left">
      <a [routerLink]="['/']" class="scrollto"><img src="../../assets/images/logo.svg" alt="" class="img-fluid"></a>
    </div>
    <nav class="main-nav float-right d-none d-lg-block">
      <ul>
        <li routerLinkActive="active"><a [routerLink]="['/strona-glowna']">Strona główna</a></li>
        <li routerLinkActive="active"><a [routerLink]="['/jak-to-dziala']">Jak to działa</a></li>
        <li routerLinkActive="active"><a class="link" [routerLink]="['/szczegoly']">Szczegóły</a></li>
        <li routerLinkActive="active"><a class="link" [routerLink]="['/cennik']">Cennik</a></li>
        <li routerLinkActive="active"><a class="link" [routerLink]="['/faq']">FAQ</a></li>
        <li routerLinkActive="active"><a class="link" [routerLink]="['/kontakt']">Kontakt</a></li>
      </ul>
    </nav><!-- .main-nav -->
  </div>
</header><!-- #header -->
