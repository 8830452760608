import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { LayoutComponent } from './layout/layout.component';
import { FaqComponent } from './faq/faq.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { DetailsComponent } from './details/details.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PricingComponent } from './pricing/pricing.component';
import { PaymentGuard } from './payment/guards/payment.guard';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ChooseProviderComponent } from './shared/components/choose-provider/choose-provider.component';

const routes: Route[] = [
  {
    path: '',
    redirectTo: 'strona-glowna',
    pathMatch: 'full'
  },
  {
    path: 'strona-glowna',
    component: IntroComponent
  },
  {
    path: 'mintos',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren:  () => import('./mintos/mintos.module').then(m => m.MintosModule),
      },
    ]
  },
  {
    path: 'estateguru',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren:  () => import('./estateguru/estateguru.module').then(m => m.EstateguruModule),
      },
    ]
  },
  {
    path: 'platnosc',
    component: LayoutComponent,
    canActivate: [PaymentGuard],
    children: [
      {
        path: '',
        loadChildren:  () => import('./payment/payment.module').then(m => m.PaymentModule),
      },
    ]
  },
  {
    path: 'report',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren:  () => import('./report/report.module').then(m => m.ReportModule),
      },
    ]
  },
  {
    path: 'wyniki',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren:  () => import('./results/results.module').then(m => m.ResultsModule),
      },
    ]
  },
  {
    path: 'faq',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: FaqComponent
      },
    ]
  },
  {
    path: 'regulamin',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: TermsAndConditionsComponent
      },
    ]
  },
  {
    path: 'wybierz-usluge',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: ChooseProviderComponent
      },
    ]
  },
  {
    path: 'cennik',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PricingComponent
      },
    ]
  },
  {
    path: 'jak-to-dziala',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: HowItWorksComponent
      },
    ]
  },
  {
    path: 'szczegoly',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DetailsComponent
      },
    ]
  },
  {
    path: 'kontakt',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: ContactComponent
      },
    ]
  },
  {
    path: 'polityka-prywatnosci',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
