<footer id="footer">
  <div class="container padding-top">
    <div class="row">
      <div class="col-sm-3 footer-info col-xs-12">
        <h3>Wirepit</h3>
        <p>Rozlicz swój PIT z platformy Mintos z naszą pomocą!</p>
      </div>

      <div class="col-sm-3 col-xs-12">
        <div><a class="link" [routerLink]="['/strona-glowna']">Strona główna</a></div>
        <div><a class="link" [routerLink]="['/jak-to-dziala']">Jak to działa</a></div>
      </div>
      <div class="col-sm-3 col-xs-12">
          <div><a class="link" [routerLink]="['/regulamin']">Regulamin</a></div>
          <div><a class="link" [routerLink]="['/faq']">FAQ</a></div>
      </div>
      <div class="col-sm-3 col-xs-12">
          <div><a class="link" [routerLink]="['/kontakt']">Kontakt</a></div>
          <div><a class="link" [routerLink]="['/polityka-prywatnosci']">Polityka prywatności</a></div>
      </div>
    </div>
  </div>
</footer>
