<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
  <h1 class="display-4">Cennik</h1>
  <p class="lead">Zdajemy sobie sprawę, że dochód generowany na platformie Mintos jest różny w zależności od użytkownika. Wychodząc Wam naprzeciw, dopasowaliśmy nasz cennik na każdą kieszeń, uzależniając płatność od wygenerowanego dochodu.</p>
</div>
<div class="container">
  <div class="card-deck mb-3 text-center">
    <div class="card mb-4 box-shadow">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">Darmowy</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">0 zł</h1>
        <ul class="list-unstyled mt-3 mb-4">
          <li>do <b>100 zł</b> przychodu</li>
          <li>wiele walut</li>
          <li>wiele plików</li>
        </ul>
      </div>
    </div>
    <div class="card mb-4 box-shadow">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">Startowy</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">5 zł</h1>
        <ul class="list-unstyled mt-3 mb-4">
          <li>od <b>100 zł</b> do <b>1000 zł</b> przychodu</li>
          <li>wiele walut</li>
          <li>wiele plików</li>
        </ul>
      </div>
    </div>
    <div class="card mb-4 box-shadow">
      <div class="card-header">
        <h4 class="my-0 font-weight-normal">Rozszerzony</h4>
      </div>
      <div class="card-body">
        <h1 class="card-title pricing-card-title">10 zł</h1>
        <ul class="list-unstyled mt-3 mb-4">
          <li>od <b>1000 zł</b> przychodu</li>
          <li>wiele walut</li>
          <li>wiele plików</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
  <h2 class="display-5">Zwroty oraz reklamacje</h2>
  <p class="lead">Reklamacje rozpatrywane są indywidualnie. W tym celu proszę o kontakt pod adresem pomoc@wirepit.pl</p>
  <p>W przypadku gdy wygenerowany przez aplikację wirepit plik wynikowy programu excel będzie uszkodzony lub niemożliwy do odczytania reklamacje oraz zwroty będą rozpatrywane pozytywnie.</p>
</div>
