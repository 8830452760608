import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChooseProviderComponent } from './components/choose-provider/choose-provider.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UploadComponent } from './components/upload/upload.component';

@NgModule({
  declarations: [UploadComponent, NotFoundComponent, ChooseProviderComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports:[UploadComponent]
})
export class SharedModule { }
