<div class="row">
  <h1>REGULAMIN</h1>
  <h2 class="display-5">ROZDZIAŁ 1. POSTANOWIENIA OGÓLNE I DEFINICJE</h2>
  <p>1. Regulamin określa zasady i warunki świadczenia usług drogą elektroniczną, w tym korzystania ze Sklepu oraz
    prawa, obowiązki i warunki odpowiedzialności Sprzedawcy i Klienta. Regulamin zawiera także informacje, do których
    przekazania Konsumentowi zobowiązany jest Sprzedawca zgodnie z obowiązującymi przepisami, w tym ustawą z dnia 30
    maja 2014 roku o prawach konsumenta (Dz.U.2014.827 ze zm.).</p>
  <p>2. Każdy Klient powinien zapoznać się z Regulaminem. Rozpoczęcie korzystania ze Sklepu oznacza akceptację
    Regulaminu.</p>
  <p>3. Regulamin dostępny jest na stronie internetowej Sklepu i udostępniany jest nieodpłatnie także przed zawarciem
    umowy. Na żądanie Klienta Regulamin jest także udostępniany w taki sposób, który umożliwia pozyskanie, odtwarzanie i
    utrwalanie jego treści za pomocą systemu teleinformatycznego, którym posługuje się Klient (np. pocztą
    elektroniczną).</p>
  <p>4. Podstawowe definicje:</p>
  <p class="padding-left">a) Regulamin: Regulamin Sklepu Internetowego;</p>
  <p class="padding-left">b) Sklep lub Sklep Internetowy: Sklep Internetowy, prowadzony przez Sprzedawcę pod adresem
    internetowym http://www.wirepit.pl</p>
  <p class="padding-left">c) Sprzedawca: Maciej Andruszko, Wrocław, ul. Hugona Kowarzyka 9, 51-128 Wrocław, prowadzący
    działalność gospodarczą pod firmą Maciej Andruszko Wiresoft, NIP: 8952204346, Regon: 383015217;</p>
  <p class="padding-left">d) Klient: osoba fizyczna, która ukończyła 18 lat i posiada pełną zdolność do czynności
    prawnych, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej, ale mogąca we własnym imieniu
    nabywać prawa i zaciągać zobowiązania, która nawiąże stosunek prawny ze Sprzedawcą w zakresie działalności Sklepu.
    Klient to także Konsument, jeśli w danej kwestii brak oddzielnych postanowień w stosunku do Konsumenta;</p>
  <p class="padding-left">e) Konsument: Klient będący osobą fizyczną dokonującą ze Sprzedawca czynności prawnej
    (zakupu), niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową;</p>
  <p class="padding-left">f) Towar lub Produkt – towary sprzedawane w Sklepie, znajdujące się w ofercie Sprzedawcy;</p>
  <p class="padding-left">g) Umowa – umowa na odległość, dotycząca zakupu Towaru, zawarta na skutek złożenia przez
    Klienta Zamówienia w Sklepie i jego przyjęcia przez Sprzedawcę;</p>
  <p class="padding-left">h) Zamówienie – dyspozycja zakupu Towaru złożona przez Klienta za pomocą środków komunikacji
    technicznej;</p>
  <p class="padding-left">i) Formularz – skrypt stanowiący środek komunikacji elektronicznej, umożliwiający złożenie
    Zamówienia.</p>

  <h2 class="display-5">ROZDZIAŁ 2. INFORMACJE PODSTAWOWE I TECHNICZNE</h2>

  <p>1. Sprzedawca oferuje następujące rodzaje usług świadczonych drogą elektroniczną – sklep internetowy.</p>
  <p>2. Sprzedawca świadczy usługi drogą elektroniczną zgodnie z Regulaminem.</p>
  <p>3. Warunkiem technicznym korzystania ze Sklepu jest posiadanie przez Klienta komputera lub innych urządzeń
    umożliwiających przeglądanie sieci Internet, dostępu do Internetu oraz aktualnego i czynnego konta poczty
    elektronicznej.</p>
  <p>4. Obowiązuje zakaz dostarczania przez Klienta treści o charakterze bezprawnym.</p>
  <p>5. Korzystanie ze Sklepu może się wiązać z zagrożeniami typowymi dla korzystania z Internetu, typu spam, wirusy,
    ataki hakerskie. Sprzedawca podejmuje działania celem przeciwdziałania tym zagrożeniom.</p>
  <p>6. Zawarcie umowy o świadczenie usług drogą elektroniczną następuje za pośrednictwem sklepu internetowego, przez
    internet. Klient może w każdej chwili zakończyć korzystanie z usług drogą elektroniczną poprzez opuszczenie sklepu
    internetowego lub poprzez usunięcie konta Klienta. W takim przypadku umowa o świadczenie usług drogą elektroniczną
    rozwiązuje się automatycznie bez konieczności składania dodatkowych oświadczeń stron.</p>

  <h2 class="display-5">ROZDZIAŁ 3. DANE OSOBOWE</h2>

  <p>1. Podane przez Klientów dane osobowe Sprzedawca zbiera i przetwarza zgodnie z obowiązującymi przepisami prawa.</p>
  <p>2. Administratorem danych osobowych Klientów jest Sprzedawca.</p>
  <p>3. Dane osobowe przetwarzane są przez administratora danych w celu realizacji zamówień składanych za pośrednictwem
    sklepu internetowego devold.pl, obsługi procesu rejestracji i logowania do Konta Klienta oraz przesyłania na
    wskazany
    adres poczty elektronicznej zamówionych informacji handlowych (newsletter).</p>
  <p>4. Podstawą przetwarzania danych osobowych jest zgoda samych Klientów, jak również ustawowe upoważnienie do
    przetwarzania danych osobowych, wnikające z ustawy o ochronie danych osobowych z dnia 29 sierpnia 1997 roku (Dz. U.
    2016.922 ze zm.) oraz z ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną (Dz.U. 2016.1030 ze
    zm.).</p>
  <p>5. Sprzedawca zapewnia, że dane osobowe Klientów nie zostaną udostępnione żadnym nieupoważnionym podmiotom. Dane
    osobowe Klientów mogą być udostępniane podmiotom uprawnionym do ich otrzymania na mocy obowiązujących przepisów
    prawa,
    w tym właściwym organom.</p>
  <p>6. Każda osoba, której przetwarzane dane dotyczą ma prawo dostępu do treści swoich danych osobowych, prawo ich
    poprawiania, uzupełniania oraz prawo żądania zaprzestania ich przetwarzania i ich usunięcia.</p>

  <h2 class="display-5">ROZDZIAŁ 4. SPRZEDAŻ</h2>

  <h2 class="display-6">§ 1. Usługi</h2>

  <p>1. Szczegółowy opis Usługi wraz z ceną znajduje się na stronie Sklepu.</p>
  <p>2. Sprzedawca zobowiązany jest do sprzedaży usługi bez wag.</p>
  <p>3. Na Towary może być udzielona gwarancja lub usługi posprzedażowe. Szczegółowa informacja w tym zakresie znajduje
    się przy opisie Towaru.</p>

  <h2 class="display-6">§ 2. Zamówienia usług i ich realizacja</h2>

  <p>1. Zamówienie może być złożone poprzez wypełnienie Formularza, dostępnego w Sklepie.</p>
  <p>2. Klient zobowiązany jest do starannego wypełnienia Formularza, podając wszystkie dane wejściowe zgodnie z
    instrukcją.</p>
  <p>3. Klient podaje w Formularzu dane oraz składa oświadczenie w sprawie akceptacji Regulaminu, przetwarzania danych
    osobowych, przekazywania informacji handlowych oraz polityki prywatności i plików cookies.</p>
  <p>4. Zamówienia usług w Sklepie można składać 24 godziny na dobę, 7 dni w tygodniu. Usługi wykonywane są od razu po
    ich
    zamówieniu.</p>
  <p>5. Potwierdzenia złożenia Zamówienia dokonuje Klient poprzez wybranie przycisku oznaczonego „Przejdź do płatności”.
    Sprzedawca wyśle Klientowi na wskazany przez Klienta adres e-mail potwierdzenie płatności.</p>


  <h2 class="display-6">§ 3. Płatności</h2>

  <p>1. Wszystkie ceny Towarów podawane w Sklepie są cenami brutto w złotych polskich.</p>
  <p>2. Po złożeniu Zamówienia Klient powinien wpłacić/przelać należność na rachunek bankowy Sklepu lub za pośrednictwem
    systemu płatności internetowych. Rozliczenia transakcji kartą kredytową i e-przelewem przeprowadzane są za
    pośrednictwem paynow.pl (usługa mBanku). Realizacja Zamówienia następuje po zaksięgowaniu wpłaty Klienta na rachunku
    bankowym Sklepu lub potwierdzenia płatności przez operatora systemu płatności internetowych.</p>
  <p>3. Sklep wystawia dowód zakupu i doręcza go Klientowi wraz z Usługą.</p>

  <h2 class="display-6">§ 4. Dostawa</h2>

  <p>1. Usługa wyświetlana jest na stronie wirepit.pl lub opcjonalnie wysyłana jest na adres mailowy wskazany przez
    Klienta w Formularzu.</p>

  <h2 class="display-6">§ 5. Dodatkowe informacje dla Konsumenta</h2>

  <p>1. Umowa nie jest zawarta na czas nieoznaczony i nie będzie podlegać automatycznemu przedłużeniu.</p>

  <p>2. Minimalny czas trwania zobowiązań Konsumenta wynikających z Umowy to czas realizacji Umowy, tj. dokonania
    zapłaty
    i odbioru Usługi.</p>

  <p>3. Korzystanie ze Sklepu przez Konsumenta nie łączy się z obowiązkiem złożenia kaucji lub udzielenia innych
    gwarancji
    finansowych.</p>

  <p>4. Sprzedawca nie stosuje kodeksu dobrych praktyk, o którym mowa w art. 2 pkt 5 ustawy z dnia 23 sierpnia 2007 r. o
    przeciwdziałaniu nieuczciwym praktykom rynkowym (Dz.U.2016.3.ze zm.).</p>

  <h2 class="display-6">ROZDZIAŁ 5. ODPOWIEDZIALNOŚĆ</h2>


  <p>1. Odpowiedzialność z tytułu rękojmi jest wyłączona w stosunkach prawnych z Klientami.</p>

  <p>2. Sprzedawca jest odpowiedzialny względem Konsumenta, jeżeli usługa ma wadę techniczną na zasadach określonych w
    przepisach kodeksu cywilnego (Dz.U.2016.380 ze zm.).</p>

  <h2 class="display-6">ROZDZIAŁ 6. PROCEDURA ROZPATRYWANIA REKLAMACJI</h2>

  <p>1. Reklamacje dotyczące wad Towaru Konsument powinien kierować na adres Sprzedawcy w formie pisemnej. O wysłaniu
    zawiadomienia można także poinformować Sprzedawcę drogą elektroniczną na adres mailowy: pomoc@wirepit.pl.</p>

  <p>2. Reklamacja powinna zawierać szczegółowy opis wady usługi i żądań Konsumenta.</p>

  <p>3. Sprzedawca zobowiązuje się rozpatrzyć reklamację w terminie 14 dni. Jeżeli Sprzedawca nie ustosunkuje się do
    reklamacji Konsumenta w terminie 14 dni, uważa się, że uznał ją za uzasadnioną.</p>

  <p>4. Jeśli reklamacja zostanie uznana, Sprzedawca podejmie odpowiednie działania zgodnie z żądaniem Konsumenta.</p>

  <p>5. Istnieje możliwość skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń w
    stosunkach prawnych z Konsumentami, w tym możliwość rozwiązywania sporów drogą elektroniczną za pomocą platformy ODR
    (online dispute resolution), dostępnej pod adresem http://ec.europa.eu/consumers/odr/.</p>

  <h2 class="display-6">ROZDZIAŁ 7. POSTANOWIENIA KOŃCOWE</h2>

  <p>1. W sprawach nieuregulowanych Regulaminem w stosunkach prawnych z Klientami lub Konsumentami zastosowanie mają
    odpowiednie przepisy powszechnie obowiązującego prawa.</p>

  <p>2. Sprzedawca zastrzega sobie prawo wprowadzania zmian do Regulaminu z zastrzeżeniem, iż do umów zawartych przed
    zmianą Regulaminu stosuje się wersję Regulaminu obowiązującą w chwili złożenia Zamówienia.</p>

  <p>3. Wszelkie odstępstwa od Regulaminu wymagają formy pisemnej pod rygorem nieważności.</p>

  <p>4. Sądem właściwym do rozstrzygnięcia sporu między stronami będzie sąd właściwy według odpowiednich przepisów.</p>
</div>
