import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { UploadModel } from './shared/models/upload.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Wirepit';
  public isLoading = false;
  public isError = false;

  constructor(
    private http: HttpClient,
    private ngcCookieConsentService: NgcCookieConsentService
  ) {
    if (localStorage.getItem('cookie_accepted') !== 'true') {
      this.ngcCookieConsentService.open();
    }
  }

  public ngOnInit(): void {
    this.ngcCookieConsentService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.chosenBefore === true && event.status === 'dismiss') {
          localStorage.setItem('cookie_accepted', 'true');
        }
      });
  }

  public onUploadChange(model: UploadModel) {
    this.isLoading = true;
    this.http.post<any>(`${environment.apiEndpoint}/upload`, { Base64String: model.base64String, FileName: model.fileName }).subscribe(({ base64String }) => {
      console.log(base64String);

      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
      const downloadLink = document.createElement('a');
      const fileName = 'excel.xlsx';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.isError = false;
    }, () => {
      this.isError = true;
      this.isLoading = false;
    }, () => this.isLoading = false);
  }
}
