<h2>
  Informacje dotyczące kwestii podatkowych na Mintos
</h2>

<h3>1. Rozliczanie transakcji w walutach innych niż PLN.</h3>
<p>
  Według przepisów podatkowych w przypadku przychodów w walutach obcych, należy przychody przeliczyć na PLN, przy czym
  przeliczenie przychodów w obcych walutach na złotówki, wygląda tak, że dla każdej transakcji w walucie obcej kursem
  przeliczenia waluty na PLN, będzie średni kurs NBP złotego z ostatniego dnia roboczego poprzedzający datę powstania
  przychodu.
</p>
<p>
  Jeśli więc mamy kilkadziesiąt transakcji na platformie Mintos w walucie obcej, to przy ręcznym przeliczeniu walut na
  PLN czeka nas sporo pracy.
  Natomiast plik Excel generowany na stronie Wirepit ułatwia nam pracę i dzięki zaimplementowanemu algorytmowi przelicza
  nam waluty obce na złotówki stosując przy tym opisaną powyżej zasadę.
</p>
<h3>2. Co jest przychodem i kosztem w generowanych przez Mintos raportach/plikach.</h3>
<p>
  Mintos do przychodów w każdej walucie zalicza:
</p>
<ul>
  <li>Otrzymane odsetki</li>
  <li>Przychody odsetkowe od ponownego wykupu</li>
  <li>Zniżkę/ zysk w przypadku transakcji na rynku wtórnym (pozycja „Capital gain”)</li>
  <li>Bonus z Mintos w postaci zwrotu gotówki</li>
</ul>
<p>
    Różnica pomiędzy wyżej opisanymi przychodami, a kosztami stanowi dochód wygenerowany  mailowo “your tax statement for operation on Mitos marketplace”
</p>
<i>Wymienione powyżej  informacje nie stanowią wykładni prawa ani nie mają charakteru czynności doradztwa podatkowego czy finansowego, a jedynie maja charakter informacyjny.</i>

