<section id="intro" class="clearfix">
  <div class="container">

    <div class="intro-img">
      <img src="../assets/images/intro-img.svg" alt="" class="img-fluid">
    </div>

    <div class="intro-info">
      <h2>Zajmiemy się Twoim podatkiem dochodowym z platformy Mintos!</h2>
      <div>
        <a [routerLink]="['/mintos']" class="btn-get-started scrollto">Zaczynamy</a>
        <a [routerLink]="['/jak-to-dziala']" class="btn-services scrollto">Szczegóły</a>
      </div>
    </div>
  </div>
</section>
<div class="container text-center">
  <h2 class="mt-3 mb-3">
    Partnerzy
  </h2>
  <a href="https://growing.money" target="_blank">
    <div class="image-container">
      <img class="image" src="../assets/images/growing-money.png"/>
    </div>
  </a>
</div>

