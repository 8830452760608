import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public scrolled: boolean;
  public sidebarOpened: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onHamburgerClick(){
    this.sidebarOpened = !this.sidebarOpened;
  }

  closeSidebar(){
    this.sidebarOpened = false;
  }

  @HostListener("window:scroll")
  onScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      this.scrolled = true;
    } else if (this.scrolled && number < 10) {
      this.scrolled = false;
    }
  }
}
