import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactDataService } from './contact-data.service';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap } from 'rxjs/operators';
import { FormState } from '../shared/models/form-state.enum';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ContactDataService]
})
export class ContactComponent implements OnDestroy {
  public contactForm: FormGroup;
  public formState: FormState = FormState.Initial;

  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private contactDataService: ContactDataService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactForm = this.formBuilder.group({
      from: new FormControl('', [Validators.required, Validators.email]),
      body: new FormControl('', [Validators.required])
    });
  }

  get from() { return this.contactForm.get('from'); }

  get body() { return this.contactForm.get('body'); }

  onSubmit(contactData: {from: string, body: string}) {
    if (this.contactForm.valid) {
      this.formState = FormState.Loading;
      this.subscription = this.recaptchaV3Service.execute('contactForm').pipe(switchMap((key: string) => {
        return this.contactDataService.sendContactForm(contactData.body, contactData.from, key);
      })).subscribe(() => {
        this.formState = FormState.Finished;
      }, error => {
        this.formState = FormState.Error;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
