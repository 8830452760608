<div class="row container">
  <h1>Skontaktuj się z nami!</h1>

  <div class="row" *ngIf="formState !== 'Loading'" style="width:100%">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)">
      <div class="form-group">
        <label for="email">Twój adres e-mail</label>
        <input type="email" class="form-control" id="email" placeholder="Podaj swój email" formControlName="from">
        <div *ngIf="from.invalid && (from.dirty || from.touched)" class="text-danger">
          <div>
            Niepoprawny adres email.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="body">Wiadomość</label>
        <textarea class="form-control" id="body" rows="8" formControlName="body"></textarea>
        <div *ngIf="body.invalid && (body.dirty || body.touched)" class="text-danger">
          <div>
            Wiadomość jest wymagana
          </div>
        </div>
      </div>
      <button class="btn btn-lg btn-success" [class.disabled]="!contactForm.valid" type="submit" [hidden]="formState === 'Finished'">Wyślij</button>
    </form>
  </div>

  <div class="row mt-3" *ngIf="formState === 'Error'">
    <div class="alert alert-danger" role="alert">
      Uppss... Coś poszło nie tak. Sróbuj ponownie.
    </div>
  </div>

  <div class="row" *ngIf="formState === 'Loading'" class="text-center">
    <div class="spinner-grow" style="width: 9rem; height: 9rem;background-color: #004a99" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="row mt-3" *ngIf="formState === 'Finished'">
    <div class="alert alert-success" role="alert">
      Email z Twoim zapytaniem został wysłany.
    </div>
  </div>
</div>

