<div class="row">
  <h1>Polityka Cookies</h1>
  <div class="entry clearfix">
    <p>&nbsp;</p>
    <ol start="1">
      <li>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach
        cookies.</li>
      <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które
        przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
        internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
        przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
      <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
        dostęp jest operator Serwisu Wirepit.pl</li>
      <li>Pliki cookies wykorzystywane są w celu:</li>
    </ol>
    <p>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika
      oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie
      Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</p>
    <p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu
      korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</p>
    <p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której
      Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</p>
    <p>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ….</p>
    <ol start="5">
      <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” &nbsp;(<i>session cookies</i>)
        oraz „stałe” (<i>persistent cookies</i>). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w
        urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia
        oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym
        Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
      <li>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</li>
    </ol>
    <p>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach
      Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach
      Serwisu;</p>
    <p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do
      wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;</p>
    <p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o
      sposobie korzystania ze stron internetowych Serwisu;</p>
    <p>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez
      Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z
      którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;</p>
    <p>e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści
      reklamowych bardziej dostosowanych do ich zainteresowań.</p>
    <ol start="7">
      <li>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa)
        domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
        dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
        szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki
        internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe
        informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
        (przeglądarki internetowej).</li>
      <li>Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności
        dostępne na stronach internetowych Serwisu.</li>
      <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez
        współpracujących z operatorem Serwisu reklamodawców oraz partnerów.</li>
    </ol>
    <p>&nbsp;</p>
  </div>
  <i>Poniższy wzór polityki cookies chroniony jest prawem autorskim, które przysługują IAB Polska. Z prezentowanego
    poniżej wzoru polityki cookies możesz skorzystać w całości lub fragmentach wyłącznie z powyższą notą o prawach
    autorskich oraz ze wskazaniem źródła informacji: &nbsp;https://wszystkoociasteczkach.pl</i>
    <h1>Klauzula informacyjna RODO w zakresie przetwarzania danych osobowych</h1>
<p>1. Administratorem danych osobowych jest Maciej Andruszko Wiresoft;NIP 8952204346; REGON 383015217</p>
<p>2. Administrator wyznaczył inspektora ochrony danych osobowych. Dane kontaktowe inspektora: Maciej Andruszko, email – pomoc@wirepit.pl</p>
<p>3. Przekazane dane osobowe przetwarzane będą w celu realizacji usług, obsługi zgłoszeń i udzielania odpowiedzi na zgłoszenia;</p>
<p>4. Kategorie danych osobowych obejmują adres e-mail użytkownika aplikacji.</p>
<p>5. Pani / Pana dane osobowe mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie administratora: dostawcy usług IT;</p>
<p>6. Państwa dane osobowe będą przechowywane przez okres istnienia prawnie uzasadnionego interesu administratora, chyba że Pani / Pan wyrazi sprzeciw wobec przetwarzania danych;</p>
<p>7. Państwa dane nie będą przekazywane do państwa trzeciego ani organizacji międzynarodowej;</p>
<p>8. Posiadają Państwo prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;</p>
<p>9. Mają Państwo prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, którym jest Prezes Urzędu Ochrony Danych Osobowych, gdy uznają Państwo, iż przetwarzanie Państwa danych osobowych narusza przepisy ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (tekst jednolity Dz. U. z 2018 r., poz. 1000) lub przepisy Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r. (Dz.Urz.UE.L Nr 119, str. 1);</p>
<p>10. Dane udostępnione przez Panią/Pana nie będą podlegały zautomatyzowanemu podejmowaniu decyzji oraz profilowaniu;</p>
<p>11. Dane pochodzą od osób, których dane dotyczą;</p>
<p>12. Podanie przez Państwa danych osobowych jest dobrowolne;</p>
</div>

