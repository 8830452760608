<h2>1. Jak otrzymać informację z Mintos o dochodach ?</h2>
<p>
  W zakładce „Moje konto” należy kliknąć w „Sprawozdanie finansowe”.
  Następnie jako country wybrać należy „Poland”, natomiast jako „Data rozpoczęcia” wybrać należy 01-01-{{year}}, a jako
  „Data zakończenia” wpisać 31-12-{{year}}. Po kliknięciu w „Prześlij” Mintos na podanego przy rejestracji maila prześle
  informację dotyczącą dochodu za interesujący nas okres (okres roku, który musimy rozliczyć w ramach podatku
  dochodowego od osób fizycznych).
</p>
<p>
  Przesłany przez Mintos mail z tematem „your tax statement for operation on Mitos marketplace” zawiera tylko krótką
  informację dotycząca przychodów osiągniętych w interesującym nas okresie ( okres roku) w każdej walucie, w której
  inwestowaliśmy w tym czasie na platformie Mintos.
</p>
<h2>2. Dlaczego informacja z Mintos jest niewystarczająca do deklaracji PIT-8C ?</h2>
<p>
  Niestety ale otrzymana przez nas mailowa informacja z Mintos dotycząca uzyskanych przychodów na platformie Mintos nie
  jest wystarczająca do uzupełnienia odpowiedniego formularza rozliczającego podatek dochodowy.
</p>
<p>
  W przypadku inwestycji w Mintos nie otrzymamy więc deklaracji PIT-8C, którą standardowo przesyłają podatnikom
  instytucje finansowe, czy firmy maklerskie. Pozostaje nam więc wyliczenie we własnym zakresie dochodu za rok podatkowy
  oraz podatku dochodowego, który powinniśmy zapłacić do końca kwietnia.
</p>
<h2>3. Jakie są nasze alternatywy, jeśli nie chcemy skorzystać z platformy Wirepit ?</h2>
<p>
  Do finalnego wyliczenia podatku do zapłaty pozostają nam następujące możliwości:</p>
<ul>
  <li>Ręczne liczenie przychodów, kosztów, dochodów oraz podatku na podstawie plików Excel, które możemy znaleźć na
    stronie Mintos (jest to dosyć żmudny i czasochłonny proces w którym łatwo o pomyłkę w szególności w przypadku sporej
    ilości transakcji w walutach innych niż PLN)</li>
  <li>Zlecenie wyliczenia podatku biurom rachunkowym (jest to kosztowne rozwiązanie).</li>
  <li>Można mimo wszystko skorzystać z platformy Wirepit.</li>
</ul>
<h2>4. Z czym wiąże się nie rozliczenie dochodów z Mintos?</h2>
<p>
  Ustawodawca nakłada na każdego z podatników obowiązek rozliczenia się z uzyskanych dochodów za konkretny rok podatkowy. Standardowo z dochodów osiągniętych na Mintos należy zapłacić 19% podatek PIT, natomiast w przypadku nierozliczenia się możemy narazić się na zaliczenie naszych dochodów do dochodów z  nieujawnionych źródeł przychodu, które opodatkowane są stawką podatkową w wysokości 75%. Na dodatek narażamy się również na naliczenie odsetek karnych. Ponadto w związku z faktem, iż zobowiązania podatkowe przedawniają się po upływie 5 lat, mamy sporą „szanse”, że osiągniętymi przez nas dochodami z Mintos w ciągu 5 lat zainteresują się organy fiskalne.
</p>
<br>
<i>Wymienione powyżej  informacje nie stanowią wykładni prawa ani nie mają charakteru czynności doradztwa podatkowego czy finansowego, a jedynie maja charakter informacyjny.</i>
