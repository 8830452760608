import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { ErrorInterceptorProvider } from './core/interceptors/error-interceptor';
import { ResultsCommunicationService } from './core/services/results-communication.service';
import { DetailsComponent } from './details/details.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { IntroComponent } from './intro/intro.component';
import { LayoutComponent } from './layout/layout.component';
import { PaymentGuard } from './payment/guards/payment.guard';
import { PricingComponent } from './pricing/pricing.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SharedModule } from './shared/shared.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const cookieConfig: NgcCookieConsentConfig = {
  'cookie': {
    'domain': 'localhost'
  },
  'position': 'bottom-right',
  'theme': 'classic',
  'palette': {
    'popup': {
      'background': '#000000',
      'text': '#ffffff',
      'link': '#ffffff'
    },
    'button': {
      'background': '#FFAB4A',
      'text': '#000000',
      'border': 'transparent'
    }
  },
  'type': 'info',
  'content': {
    'message': 'Strona wykorzystuje pliki cookies. W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie zmiany ustawień dotyczących cookies. Więcej szczegółów w naszej Polityce Cookies.',
    'dismiss': 'Akceptuję',
    'deny': 'Refuse cookies',
    'link': 'Zobacz więcej',
    'href': 'https://wirepit.pl/polityka-prywatnosci',
    'policy': 'Polityka Cookies'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IntroComponent,
    LayoutComponent,
    FaqComponent,
    HowItWorksComponent,
    DetailsComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    PricingComponent,
    TermsAndConditionsComponent
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    RecaptchaV3Module,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lcoqd8UAAAAAFDvD701XroJFHEpv7aSMGcoa_ru' },
    PaymentGuard,
    ResultsCommunicationService,
    ErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
